import {
  OUTFIT_FEEDBACK,
  OUTFIT_RECO_CARD_CLICK,
  OUTFIT_RECO_CARD_VIEW,
  OUTFIT_RECO_MODAL_CAROUSEL_CLICK,
  OUTFIT_RECO_MODAL_VIEW_DETAILS_CLICK
} from 'constants/amethyst';

type OutfitRecoGroup = {
  groupId: string;
  styleIds: string[];
};

type BaseOutfitRecosParams = {
  seedStyleId: string;
  outfitGroup: OutfitRecoGroup;
};

// Card Event Params
type OutfitRecoCardViewParams = Omit<BaseOutfitRecosParams, 'outfitGroup'> & {
  outfitGroups: OutfitRecoGroup[];
};

type OutfitRecoCardClickParams = BaseOutfitRecosParams & {
  clickStyleId: string;
  outfitNumber: number;
};

type OutfitFeedbackParams = BaseOutfitRecosParams & {
  isUpvote: boolean;
  outfitNumber: number;
};

// Modal Event Params
type OutfitRecoModalCarouselClickParams = BaseOutfitRecosParams & {
  clickStyleId: string;
};

type OutfitRecoModalViewDetailsClickParams = BaseOutfitRecosParams & {
  clickStyleId: string;
};

// Events
export const evOutfitRecoCardView = ({ seedStyleId, outfitGroups }: OutfitRecoCardViewParams) => ({
  [OUTFIT_RECO_CARD_VIEW]: {
    seedStyleId,
    outfitGroups
  }
});

export const evOutfitRecoCardClick = ({ seedStyleId, outfitGroup, clickStyleId, outfitNumber }: OutfitRecoCardClickParams) => ({
  [OUTFIT_RECO_CARD_CLICK]: {
    seedStyleId,
    outfitGroup,
    clickStyleId,
    outfitNumber
  }
});

export const evOutfitFeedbackClick = ({ seedStyleId, outfitGroup, isUpvote, outfitNumber }: OutfitFeedbackParams) => ({
  [OUTFIT_FEEDBACK]: {
    seedStyleId,
    outfitGroup,
    isUpvote,
    outfitNumber
  }
});

export const evOutfitRecoModalCarouselClick = ({ seedStyleId, outfitGroup, clickStyleId }: OutfitRecoModalCarouselClickParams) => ({
  [OUTFIT_RECO_MODAL_CAROUSEL_CLICK]: {
    seedStyleId,
    outfitGroup,
    clickStyleId
  }
});

export const evOutfitRecoModalViewDetailsClick = ({ seedStyleId, outfitGroup, clickStyleId }: OutfitRecoModalViewDetailsClickParams) => ({
  [OUTFIT_RECO_MODAL_VIEW_DETAILS_CLICK]: {
    seedStyleId,
    outfitGroup,
    clickStyleId
  }
});
