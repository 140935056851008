// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeArrowLeftSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      d="M2.65145 7.64905C2.45619 7.84431 2.45619 8.1609 2.65145 8.35616L5.91968 11.6244C6.11494 11.8196 6.43152 11.8196 6.62679 11.6244C6.82205 11.4291 6.82205 11.1125 6.62679 10.9173L4.21208 8.50257L13.0258 8.50257C13.302 8.50257 13.5258 8.27871 13.5258 8.00257C13.5258 7.72643 13.302 7.50257 13.0258 7.50257L4.21214 7.50257L6.62679 5.08793C6.82205 4.89267 6.82205 4.57608 6.62679 4.38082C6.43152 4.18556 6.11494 4.18556 5.91968 4.38082L2.65145 7.64905Z"
      fill="currentcolor"
    />
  </Icon>
);

export default UtilityStrokeArrowLeftSmallIcon;
