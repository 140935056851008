import React, { useCallback } from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { ProductStyle } from 'types/cloudCatalog';

import styles from 'styles/components/productdetail/stylepicker/colorChooser.scss';

interface Props {
  id?: string;
  isGiftCard: boolean;
  onColorChange: (styleId: string, colorName: string, isNew: string) => void;
  selectedStyle: ProductStyle;
  showColorLabel?: boolean;
  showStyleChooserPrefix: boolean;
  styleList: ProductStyle[];
}

const makeOnColorChangeAdapter = (onColorChange: Props['onColorChange']) => (e: React.ChangeEvent<HTMLSelectElement>) => {
  const { value, selectedIndex, options } = e.target as HTMLSelectElement;
  const option = options?.[selectedIndex];
  if (option) {
    const selectedColorText = option.text;
    onColorChange(value, selectedColorText, 'false');
  }
};

const ColorChooser = ({ selectedStyle, styleList, onColorChange, showStyleChooserPrefix, showColorLabel = true, isGiftCard, id }: Props) => {
  const colorName = (selectedStyle || {}).color;
  const colorLabel = isGiftCard ? 'Gift Amount' : 'Color';
  const selectId = `${id || 'pdp'}-color-select`;
  const { testId } = useMartyContext();

  const onColorChangeAdapter = useCallback(makeOnColorChangeAdapter(onColorChange), [onColorChange]);

  return (
    <div className={styles.styleChooserSection}>
      {styleList.length > 1 ? (
        <div className={styles.styleChooserControlWrapper}>
          {showColorLabel && (
            <label htmlFor={selectId} data-test-id={testId('color-dropdown-label')}>
              {colorLabel}:
            </label>
          )}
          <select
            id={selectId}
            name="styleId"
            className={cn(styles.styleChooserControl, {
              [styles.giftCard]: isGiftCard
            })}
            value={selectedStyle && selectedStyle.styleId}
            onChange={onColorChangeAdapter}
            data-test-id={testId('pdp-color')}
          >
            {styleList.map(({ styleId, color }) => (
              <option key={styleId} value={styleId}>
                {color}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className={styles.styleChooserText} data-test-id={testId('pdp-color')}>
          <input type="hidden" name="styleId" value={styleList[0]?.styleId} />
          {showStyleChooserPrefix && <span className={styles.dimensionLabel}>Color: </span>}
          {colorName}
        </div>
      )}
      <meta itemProp="color" content={colorName} />
    </div>
  );
};

export default ColorChooser;
/* eslint-enable */
