import React from 'react';
import { useInView } from 'react-intersection-observer';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { AriaLiveTee } from 'components/common/AriaLive';
import ProductUtils from 'helpers/ProductUtils';
import type { ProductSizing, ProductStyle } from 'types/cloudCatalog';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import type { SelectedSizing } from 'types/product';
import AddToCartSticky from 'components/productdetail/stylepicker/AddToCartSticky';
import { ADD_TO_CART_BUTTON_ID } from 'constants/addToCart';
import { stringTemplate } from 'helpers/stringTemplate';

import css from 'styles/components/productdetail/addToCart.scss';

interface Props {
  addToCartText: string;
  productDetail?: FormattedProductBundle;
  className?: string;
  useStickyAddToCart: boolean;
  isGiftCard: boolean;
  lowStockMessage: string;
  onAddToCart: (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => void;
  onOpenProductNotifyMe: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onHideSelectSizeTooltip: () => void;
  onShowSelectSizeTooltip: () => void;
  productStyle: ProductStyle;
  selectedSizing: SelectedSizing;
  sizing: ProductSizing;
  form?: string;
}

function formatStockMessage(message: string) {
  const pattern = /^Only (\d+) in stock$/;
  const match = message.match(pattern);
  return match ? `Only ${match[1]} left in stock!` : message;
}

export const AddToCart = (props: Props) => {
  const {
    addToCartText,
    productDetail,
    className,
    useStickyAddToCart,
    isGiftCard,
    lowStockMessage,
    onAddToCart,
    onHideSelectSizeTooltip,
    onShowSelectSizeTooltip,
    productStyle,
    productStyle: { colorId, productId },
    selectedSizing,
    sizing,
    form
  } = props;
  const {
    marketplace: {
      pdp: { oosDisableButton }
    },
    preventOnTouchDevice,
    testId
  } = useMartyContext();

  let onHand = 1;
  let buttonText = addToCartText;
  let stockMessage: string | undefined;
  let stockInput: JSX.Element | undefined;
  let stockId: string | undefined;
  let isDisabled: boolean | undefined;
  const stock = ProductUtils.getStockBySize(sizing.stockData, colorId, selectedSizing);

  // If all dimensions are chosen, check the stock
  const isSelectionValid = ProductUtils.isSizeSelectionComplete(sizing, selectedSizing);
  if (!stock) {
    isDisabled = oosDisableButton;
    onHand = 0;
    buttonText = 'Out of Stock';
  }

  if (isSelectionValid) {
    if (stock) {
      stockInput = <input type="hidden" name="stockId" value={stock.id} />;
      stockId = stock.id;
      onHand = parseInt(stock.onHand);
      if (onHand < 10 && lowStockMessage) {
        stockMessage = stringTemplate(lowStockMessage, { onHand });
      }
    } else {
      isDisabled = oosDisableButton;
      onHand = 0;
      buttonText = 'Out of Stock';
    }
  }

  const [addToCartRef, inView, entry] = useInView({ threshold: 0 });

  return (
    <div className={cn(css.addToCartControl, { [css.giftCard]: isGiftCard }, className)}>
      {stockInput}
      <input type="hidden" name="productId" value={productId} />
      <input type="hidden" name="colorId" value={colorId} />
      {stockMessage && (
        <div className={css.stockMessage} data-test-id={testId('stockMessage')}>
          <AriaLiveTee>{formatStockMessage(stockMessage)}</AriaLiveTee>
        </div>
      )}
      <div className={css.btnContainer}>
        <button
          type="submit"
          id={ADD_TO_CART_BUTTON_ID}
          className={css.cartButton}
          onMouseEnter={preventOnTouchDevice(onShowSelectSizeTooltip)}
          onMouseLeave={preventOnTouchDevice(onHideSelectSizeTooltip)}
          onFocus={preventOnTouchDevice(onShowSelectSizeTooltip)}
          onBlur={preventOnTouchDevice(onHideSelectSizeTooltip)}
          disabled={isDisabled}
          data-stock-id={stockId}
          data-track-action="Product-Page"
          data-track-label="PrForm"
          data-track-value="Add-To-Cart"
          data-test-id={testId('addToCart')}
          ref={addToCartRef}
          form={form}
        >
          {buttonText}
        </button>
      </div>
      {useStickyAddToCart && (
        <AddToCartSticky
          inView={inView}
          entry={entry}
          productDetail={productDetail}
          addToCartText={buttonText}
          isDisabled={onHand < 1}
          stockId={stockId}
          productStyle={productStyle}
          isSelectionValid={isSelectionValid}
          onAddToCart={onAddToCart}
          onShowSelectSizeTooltip={onShowSelectSizeTooltip}
          onHideSelectSizeTooltip={onHideSelectSizeTooltip}
        />
      )}
    </div>
  );
};

export default AddToCart;
