import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { AppState } from 'types/app';

import css from 'styles/components/productdetail/stylepicker/belowAddToCartSlot.scss';

interface PdpBelowAddToCartComponent {
  copyBoldPrefix: string;
  copyMain: string;
  copyLinkSuffixText: string;
  gae: string;
  image: string;
  imageAlt: string;
  linkUrl: string;
}

type Props = ConnectedProps<typeof connector>;

type OptionalMarkup = React.ReactNode | undefined;
type SymphonyField = string | undefined;

function makeCopyBoldPrefix(copyBoldPrefix: SymphonyField): OptionalMarkup {
  return (
    copyBoldPrefix && (
      <>
        <strong className={css.boldPrefix}>{copyBoldPrefix}</strong>{' '}
      </>
    )
  );
}

// I chose to write this function so the next developer who comes along is more
// aware that this component will be configured by less technical individuals
// and thus we should handle missing data at all possible junctures.
//
function makeCopyMain(copyMain: SymphonyField): OptionalMarkup {
  return copyMain;
}

function makeCopyLinkSuffix(text: SymphonyField, url: SymphonyField): OptionalMarkup {
  if (text && url) {
    return (
      <>
        {' '}
        <a className={css.link} href={url}>
          {text}
        </a>
      </>
    );
  }
  return text && <> {text}</>;
}

function makeImage(src: SymphonyField, alt: SymphonyField, linkUrl: SymphonyField): OptionalMarkup {
  if (!src) {
    return;
  }
  if (linkUrl) {
    return (
      <a href={linkUrl}>
        <img className={css.image} src={src} alt={alt} />
      </a>
    );
  }
  return <img className={css.image} src={src} alt={alt} />;
}

export function BelowAddToCartSlot({ component }: Props) {
  const { copyBoldPrefix, copyMain, copyLinkSuffixText, gae, image, imageAlt, linkUrl } = component;
  return (
    <div className={css.wrapper}>
      <div className={css.message} data-gae={gae}>
        {makeCopyBoldPrefix(copyBoldPrefix)}
        {makeCopyMain(copyMain)}
        {makeCopyLinkSuffix(copyLinkSuffixText, linkUrl)}
      </div>
      {makeImage(image, imageAlt, linkUrl)}
    </div>
  );
}

function mapStateToProps(state: AppState) {
  // this is annotated as Partial<T> because we should not rely on fields being
  // configured correctly in Symphony
  const component: Partial<PdpBelowAddToCartComponent> = state.headerFooter.content.Global.slotData.pdpBelowAddToCart;
  return { component };
}

const connector = connect(mapStateToProps);
export default connector(BelowAddToCartSlot);
