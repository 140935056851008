import type { FunctionComponent, MouseEvent } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { parse } from 'query-string';
import ExecutionEnvironment from 'exenv';

import { cn } from 'helpers/classnames';
import shippingBox from 'images/shipping-box.svg';
import type { AppState } from 'types/app';
import type { ProductStyle } from 'types/cloudCatalog';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import useMartyContext from 'hooks/useMartyContext';
import { isLeftClickEvent, isModifiedEvent, onEvent } from 'helpers/EventHelpers';
import { constructMSAImageUrl } from 'helpers/index';
import { generateRetinaImageParams } from 'helpers/ProductUtils';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { isDesktop } from 'helpers/ClientUtils';
import { selectOutfitRecos } from 'selectors/outfitRecos';
import { redirectTo } from 'actions/redirect';
import { selectQueryParams } from 'selectors/influencer';
import { INLINE_OUTFIT_RECO_HEIGHT, OUTFIT_RECO_REF_TAG } from 'constants/outfitRecos';
import UtilityStrokeArrowLeftSmallIcon from 'tailwind/components/Icons/UtilityStrokeArrowLeftSmallIcon';
import UtilityStrokeArrowLeftMediumIcon from 'tailwind/components/Icons/UtilityStrokeArrowLeftMediumIcon';
import { ADD_TO_CART_BUTTON_ID } from 'constants/addToCart';
import type { RecoCardProduct } from 'types/outfitRecos';
import { generateOutfitGroup } from 'helpers/outfitRecoUtils';
import { track } from 'apis/amethyst';
import { evOutfitRecoModalCarouselClick } from 'events/outfitRecos';
import { selectIsMobile } from 'selectors/headerFooter';

import css from 'styles/components/productdetail/addToCartSticky.scss';
// eslint-disable-next-line css-modules/no-unused-class
import cssAddToCart from 'styles/components/productdetail/addToCart.scss';

type InlineRecoProductProps = {
  product: RecoCardProduct;
  onClick: () => void;
  itemCss?: string;
};

export const InlineRecoProduct = (props: InlineRecoProductProps) => {
  const {
    product: { brand, productName, imageId },
    itemCss,
    onClick
  } = props;

  const imageDimensions = { width: 256, height: 256, autoCrop: true };

  const { testId } = useMartyContext();
  const label = brand ? `View ${brand} ${productName}` : 'View current product';
  const defaultPreventingClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (isLeftClickEvent(e) && !isModifiedEvent(e)) {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <button
      type="button"
      className={itemCss}
      onClick={defaultPreventingClickHandler}
      aria-label={label}
      rel="nofollow"
      data-test-id={testId('outfitRecoQuickViewProduct')}
    >
      <img alt={label} src={constructMSAImageUrl(imageId, imageDimensions)} />
    </button>
  );
};

interface Props {
  addToCartText: string;
  isAppAdvertisementShowing: boolean;
  productDetail?: FormattedProductBundle;
  inView: boolean;
  entry: IntersectionObserverEntry | undefined;
  isDisabled: boolean | undefined;
  isSelectionValid: boolean;
  stockId: string | undefined;
  productStyle: ProductStyle;
  onHideSelectSizeTooltip: () => void;
  onShowSelectSizeTooltip: () => void;
  onAddToCart: (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => void;
}

/**
 * PDP Sticky Add To Cart component ids
 */
const CONFIG = {
  STICKY_PARENT: 'root', // The parent node for the sticky add to cart
  BUY_BOX_FORM: 'buyBoxForm', // The buy box form
  SIZE_SELECTION: 'sizingChooser' // The container for the sizing options
};

/**
 * PDP Sticky Add To Cart Button
 * @returns {FunctionComponent}
 */
const AddToCartSticky: FunctionComponent<Props> = ({
  inView,
  entry,
  addToCartText,
  productDetail,
  isDisabled,
  stockId,
  productStyle,
  isSelectionValid,
  isAppAdvertisementShowing,
  onAddToCart,
  onShowSelectSizeTooltip,
  onHideSelectSizeTooltip
}) => {
  const {
    preventOnTouchDevice,
    testId,
    marketplace: { pdp: pdpConfig }
  } = useMartyContext();

  // Retrieves the featured image
  const featuredImage = productStyle.images?.[0] || false;

  // Figures out pricing
  const { originalPrice, percentOff, price, styleId } = productStyle;
  const hasDiscount = percentOff !== '0%';

  const { ref } = parse(useSelector(selectQueryParams));
  const { currentlyViewingOutfitReco, sourceProductUrl } = useSelector(selectOutfitRecos);
  const isMobile = useSelector(selectIsMobile);

  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState(false);
  const [isAddToCartOverLappingWithOutfitReco, setIsAddToCartOverLappingWithOutfitReco] = useState(false);

  // Mounts the sticky add to cart button on the "root" div
  const addToCartStickyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const martyRoot = document.getElementById(CONFIG.STICKY_PARENT);
    const addToCartStickyNode = addToCartStickyRef.current;
    if (martyRoot && addToCartStickyNode) {
      martyRoot.appendChild(addToCartStickyNode);
    }
    return () => {
      if (martyRoot && addToCartStickyNode) {
        martyRoot.removeChild(addToCartStickyNode);
      }
    };
  }, [addToCartStickyRef]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // Checks upper boundary
  const [upperBoundary, setUpperBoundary] = useState(false);
  const [isPageOffset, setIsPageOffset] = useState(false);
  const checkUpperBoundary = useCallback(
    debounce(() => {
      const addToCartStickyNode = addToCartStickyRef.current;
      if (addToCartStickyNode) {
        const topDistance = window.pageYOffset + addToCartStickyNode.getBoundingClientRect().top;
        setUpperBoundary(topDistance > 0 && topDistance < 290);

        /*
          isPageOffset hasn't been set yet
          and pageYOffset is greater than 0
        */
        if (!isPageOffset && window.pageYOffset) {
          setIsPageOffset(true);
        }
      }
    }, 250),
    []
  );

  const updateIsAddToCartOverLappingWithOutfitReco = useCallback(
    debounce(() => {
      isMounted &&
        setIsAddToCartOverLappingWithOutfitReco(
          window.innerHeight - document.getElementById(ADD_TO_CART_BUTTON_ID)?.getBoundingClientRect().top! < INLINE_OUTFIT_RECO_HEIGHT
        );
    }, 0),
    [isMounted]
  );

  useEffect(() => {
    const upperBoundaryScrollHandler = onEvent(window, 'scroll', checkUpperBoundary);
    const overlapScrollHandler = onEvent(window, 'scroll', updateIsAddToCartOverLappingWithOutfitReco);
    const resizeHandler = onEvent(window, 'resize', checkUpperBoundary);
    return () => {
      upperBoundaryScrollHandler.unbind();
      overlapScrollHandler.unbind();
      resizeHandler.unbind();
    };
  }, [checkUpperBoundary, updateIsAddToCartOverLappingWithOutfitReco]);

  // Handles add to cart action
  const addToCartAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isSelectionValid || isDisabled) {
      // 1 - If user is missing selections, scrolls smoothly the user to it
      // 2 - If the item is auto of stock, scrolls with no animation to it (the out of stock modal actually break the animation)
      document.getElementById(CONFIG.SIZE_SELECTION)?.scrollIntoView({
        behavior: isDisabled ? 'auto' : 'smooth',
        block: 'center'
      });
    } else {
      // 3 - User is good to go, scrolls with no animation to it (the add to cart success modal actually break the animation)
      document.getElementById(CONFIG.STICKY_PARENT)?.scrollIntoView();
    }
    onAddToCart(e);
  };

  const shouldShowInlineOutfitReco = ref === OUTFIT_RECO_REF_TAG && currentlyViewingOutfitReco?.productList.length;

  /*
    if you are desktop show button immediately
  */
  const shouldShowInitially = isDesktop() || isPageOffset;
  const pageScrolled = ExecutionEnvironment.canUseDOM ? document.getElementById(ADD_TO_CART_BUTTON_ID)?.getBoundingClientRect().top! < 0 : false;
  const shouldShowAddToCartButton = entry && entry?.boundingClientRect.top > 0 && !inView && shouldShowInitially;
  const shouldShowAddToCartWithOutfitReco =
    (shouldShowAddToCartButton || (shouldShowInlineOutfitReco && isAddToCartOverLappingWithOutfitReco)) && !pageScrolled;
  // footer will contain either Outfit Recommendations or Add to Cart button
  const shouldShowFooter = shouldShowInlineOutfitReco || shouldShowAddToCartWithOutfitReco;

  const buildInlineOutfitReco = () => {
    const onProductSwitch = (product: RecoCardProduct) => {
      const { complementaryGroupId, productList } = currentlyViewingOutfitReco!;

      const { styleId: clickStyleId } = product;

      const { styleId: seedStyleId } = productList.find(product => product.currentlyViewing) || {};

      const outfitGroup = generateOutfitGroup(complementaryGroupId, productList);
      track(() => [evOutfitRecoModalCarouselClick, { seedStyleId, outfitGroup, clickStyleId }]);
      dispatch(redirectTo(`${product.productUrl}?ref=${OUTFIT_RECO_REF_TAG}`));
    };

    return (
      <div className={css.outfitChrome}>
        <button
          type="button"
          aria-label="Go back to source product"
          className={css.backToSourceProduct}
          onClick={() => dispatch(redirectTo(`${sourceProductUrl}`))}
        >
          {isMobile ? <UtilityStrokeArrowLeftSmallIcon size={16} /> : <UtilityStrokeArrowLeftMediumIcon size={24} />}
        </button>
        <h2 className={css.outfitHeading}>Styling Ideas</h2>
        {currentlyViewingOutfitReco?.productList.map(product => (
          <InlineRecoProduct
            key={product.styleId}
            product={product}
            onClick={() => onProductSwitch(product)}
            itemCss={cn(css.outfitProductButton, { [css.selected]: product.styleId === styleId })}
          />
        ))}
      </div>
    );
  };

  // adding here for code reuse, move this to a new component if more features are added
  const productInfoWithAddToCartButton = () => (
    <>
      {featuredImage && (
        <div className={cn(css.featuredImage, css.productInfo)}>
          <img
            alt={featuredImage.type}
            src={constructMSAImageUrl(featuredImage.imageId, {
              width: 93,
              height: 62,
              autoCrop: true
            })}
            srcSet={`${constructMSAImageUrl(
              featuredImage.imageId,
              generateRetinaImageParams(
                {
                  width: 93,
                  height: 62,
                  autoCrop: true
                },
                2
              )
            )} 2x`}
          />
        </div>
      )}
      <div className={cn(css.productName, css.productInfo)}>{`${productDetail?.brandName} ${productDetail?.productName}`}</div>
      {pdpConfig.showFreeShipping && (
        <div className={cn(css.shipsFree, css.productInfo)}>
          <img src={shippingBox} alt="Ships Free" />
          ships free
        </div>
      )}
      <div className={cn(css.price, css.productInfo, { [css.hasDiscount]: hasDiscount })}>
        {price}
        {hasDiscount && <span className={css.originalPrice}>{originalPrice}</span>}
      </div>
      <div className={css.addToCart}>
        <button
          className={cn(cssAddToCart.cartButton, {
            [css.disabled]: isDisabled
          })}
          type="submit"
          form={CONFIG.BUY_BOX_FORM}
          data-stock-id={stockId}
          data-track-action="Product-Page"
          data-track-label="PrForm"
          data-track-value="Add-To-Cart-Sticky"
          data-test-id={testId('addToCartSticky')}
          onClick={addToCartAction}
          onMouseEnter={preventOnTouchDevice(onShowSelectSizeTooltip)}
          onMouseLeave={preventOnTouchDevice(onHideSelectSizeTooltip)}
          onFocus={preventOnTouchDevice(onShowSelectSizeTooltip)}
          onBlur={preventOnTouchDevice(onHideSelectSizeTooltip)}
        >
          {addToCartText}
        </button>
      </div>
    </>
  );

  return (
    <div ref={addToCartStickyRef}>
      <div
        data-test-id={testId('stickyAddToCartBanner')}
        aria-hidden={!entry}
        ref={addToCartStickyRef}
        className={cn(css.wrapper, {
          [css.shouldStick]: entry && shouldShowInitially && !inView && !upperBoundary && !shouldShowFooter,
          [css.stickToTheTop]: (entry && entry?.boundingClientRect.top <= 0) || pageScrolled,
          [css.appDownloadBanner]: isAppAdvertisementShowing
        })}
      >
        <div className={css.container}>{productInfoWithAddToCartButton()}</div>
      </div>
      <div
        aria-hidden={!entry}
        className={cn(css.wrapper, css.stickToTheBottom, {
          [css.addBaseBackground]: shouldShowInlineOutfitReco,
          [css.shouldStick]: shouldShowFooter,
          [css.appDownloadBanner]: isAppAdvertisementShowing
        })}
      >
        <div
          className={cn(css.container, {
            [css.inlineRecoFooter]: shouldShowInlineOutfitReco
          })}
        >
          {shouldShowInlineOutfitReco && buildInlineOutfitReco()}
          {shouldShowAddToCartWithOutfitReco && !upperBoundary && productInfoWithAddToCartButton()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAppAdvertisementShowing: state?.appAdvertisement?.isShowing
});

export default withErrorBoundary('AddToCartSticky', connect(mapStateToProps)(AddToCartSticky));
