// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeArrowLeftMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M5.65658 15.6517C5.46132 15.8469 5.46132 16.1635 5.65658 16.3588L12.193 22.8952C12.3883 23.0905 12.7049 23.0905 12.9001 22.8952C13.0954 22.7 13.0954 22.3834 12.9001 22.1881L7.21718 16.5051L26.0518 16.5051C26.3279 16.5051 26.5518 16.2813 26.5518 16.0051C26.5518 15.729 26.3279 15.5051 26.0518 15.5051L7.2173 15.5051L12.9001 9.8223C13.0954 9.62704 13.0954 9.31046 12.9001 9.1152C12.7049 8.91993 12.3883 8.91993 12.193 9.1152L5.65658 15.6517Z"
      fill="currentcolor"
    />
  </Icon>
);

export default UtilityStrokeArrowLeftMediumIcon;
