import React, { Component } from 'react';
import PropTypes from 'prop-types';
import type { Location } from 'history';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { evProductDimensionSelected } from 'events/product';
import { trackError } from 'helpers/ErrorUtils';
import ProductUtils from 'helpers/ProductUtils';
import AddToCart from 'components/productdetail/stylepicker/AddToCart';
import BelowAddToCartSlot from 'components/productdetail/stylepicker/BelowAddToCartSlot';
import ColorChooser from 'components/productdetail/stylepicker/ColorChooser';
import SizingChooser from 'components/productdetail/stylepicker/SizingChooser';
import TwoDayShippingPerk from 'components/productdetail/stylepicker/TwoDayShippingPerk';
import SwankySwatchBox from 'components/productdetail/swankyswatch/SwankySwatchBox';
import PageContent from 'components/landing/PageContent';
import marketplace from 'cfg/marketplace.json';
import { track } from 'apis/amethyst';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { MapSomeDimensionIdTo, ProductStyle, SizingValue } from 'types/cloudCatalog';
import type { FormattedProductSizing, ProductDetailState, StyleThumbnail } from 'reducers/detail/productDetail';
import type { SelectedSizing } from 'types/product';
import { stockSelectionCompleted } from 'store/ducks/productDetail/actions';

import css from 'styles/components/productdetail/stylePicker.scss';

// TODO don't capture the marketplace like this. access these fields with the
//      useMartyContext() hook in render()
const {
  pdp: { addToCartText, lowStockMessage, sizingPlaceholder, showStyleChooserPrefix, hideTwoDayShippingPerk }
} = marketplace;

interface DefaultProps {
  makeProductNotifyMe?: () => JSX.Element;
  onOpenProductNotifyMe: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showOosNotifyMe: boolean;
  styleList: ProductStyle[];
  product: ProductDetailState;
  productId: string;
  productType: string;
  productImage: string;
  productTitle?: string;
  sizing: FormattedProductSizing;
  genders: string[];
  selectedSizing: SelectedSizing;
  selectedStyle: ProductStyle;
  thumbnails: StyleThumbnail[];
  dimensionValidation: Partial<MapSomeDimensionIdTo<boolean>>;
  onStockChange: (
    styleId: string,
    selectedSizing: SelectedSizing,
    {
      label,
      name,
      selectedOption
    }: {
      label: string | null;
      name?: string;
      selectedOption?: SizingValue;
    }
  ) => void;
  onAddToCart: (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => void;
  addToCartAction: string;
  hydraBelowAddToCartSlot: boolean;
  isGiftCard: boolean;
  isSelectSizeTooltipVisible: boolean | undefined;
  isSelectSizeTooltipHighlighted: boolean | undefined;
  onShowSelectSizeTooltip: () => void;
  onHideSelectSizeTooltip: () => void;
  onUnhighlightSelectSizeTooltip: () => void;
  showSizeGender: boolean;
  allowTwoDayShippingPrimePerk: boolean;
  showSizeChartLink: boolean;
  sizingPredictionId?: string | null;
  isOnDemandEligible?: boolean | null | undefined;
  hasRecommendedSizing: boolean;
  location: Location;
  sizeSymphonyContent?: unknown;
  addToCartSymphonyContent?: unknown;
  pageType: string;
  includeColorDropDown?: boolean;
  id?: string;
  showAddToCart?: boolean;
  useDropdowns: boolean;
  useStickyAddToCart: boolean;
  isPreview?: boolean;
  productSizeChanged?: Function;
  isHydraColorLegend?: boolean;
  hidePerks?: boolean;
  showSymphonySizeBuyBox?: boolean;
}

type AirplaneSeatSizingActionProps = {
  productAgeGroupChanged?: Function;
  productGenderChanged?: Function;
  productSingleShoeSideChanged?: Function;
  productSizeRangeChanged?: Function;
  productSizeUnitChanged?: Function;
  validateDimensions?: Function;
  removeGenderFilter?: boolean;
};

type Props = DefaultProps & PropsFromRedux & Partial<AirplaneSeatSizingActionProps>;

export class StylePicker extends Component<Props> {
  static contextTypes = {
    testId: PropTypes.func,
    router: PropTypes.object
  };

  static defaultProps = {
    includeColorDropDown: true
  };

  trackColorChangeEvent = (styleId: string, colorName: string, isNew: string | undefined) => {
    const { pageType } = this.props;
    const eventData = {
      dimension: 'COLOR_DIMENSION',
      dimensionId: styleId,
      dimensionLabel: colorName,
      sourcePage: pageType,
      isNew: isNew === 'true'
    };
    track(() => [evProductDimensionSelected, eventData]);
  };

  onColorChange = (styleId: string, colorName: string | undefined, isNew: string | undefined) => {
    const {
      props: { onStockChange, selectedSizing },
      trackColorChangeEvent
    } = this;
    onStockChange(styleId, selectedSizing, { label: 'color' });
    trackColorChangeEvent(styleId, colorName!, isNew);
  };

  onSizeChange = (options: SizingValue[], event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | React.MouseEvent<HTMLInputElement>) => {
    const { onStockChange, pageType, productId, selectedSizing, selectedStyle, sizing } = this.props;
    const newSizes = Object.assign({}, selectedSizing, {});
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;
    const trackLabel = target.getAttribute('data-track-label');
    newSizes[name] = value;
    const selectedOption = options.find(option => option.id === value);
    onStockChange(selectedStyle.styleId, newSizes, {
      label: trackLabel,
      name,
      selectedOption
    });
    if (selectedOption) {
      ProductUtils.generateProductDimensionSelectedAmethystEvent(
        sizing.dimensionIdToName[name],
        selectedOption.value,
        pageType,
        options,
        selectedSizing,
        name,
        value,
        sizing,
        selectedStyle.colorId,
        selectedStyle
      );
    } else if (value) {
      trackError(`Unable to find matching option for size id ${value} on productId ${productId}`, 'ERROR');
    }
  };

  makeColorChooser = (showStyleChooserPrefix: boolean) => {
    const {
      props: { isGiftCard, product, productType, selectedStyle, styleList, useDropdowns, isHydraColorLegend, pageType },
      onColorChange
    } = this;

    const { detail: { sizing: { airplaneCache = undefined } = {} } = {} } = product;

    if (!useDropdowns) {
      return (
        <SwankySwatchBox
          isGiftCard={isGiftCard}
          onColorChange={onColorChange}
          productState={product}
          airplaneCache={airplaneCache}
          productType={productType}
          selectedStyle={selectedStyle}
          styleList={styleList}
          isHydraColorLegend={isHydraColorLegend}
          pageType={pageType}
        />
      );
    }

    return (
      <ColorChooser
        isGiftCard={isGiftCard}
        onColorChange={onColorChange}
        selectedStyle={selectedStyle}
        showColorLabel={true}
        showStyleChooserPrefix={showStyleChooserPrefix}
        styleList={styleList}
      />
    );
  };

  render() {
    const {
      makeProductNotifyMe,
      onAddToCart,
      onOpenProductNotifyMe,
      addToCartAction,
      selectedStyle,
      showOosNotifyMe,
      sizing,
      selectedSizing,
      product,
      productType,
      genders,
      dimensionValidation,
      onShowSelectSizeTooltip,
      onHideSelectSizeTooltip,
      hydraBelowAddToCartSlot,
      includeColorDropDown,
      isGiftCard,
      isSelectSizeTooltipVisible,
      isSelectSizeTooltipHighlighted,
      showSizeGender,
      showSizeChartLink,
      sizingPredictionId,
      isOnDemandEligible,
      hasRecommendedSizing,
      location,
      sizeSymphonyContent,
      addToCartSymphonyContent,
      id,
      showAddToCart = true,
      useDropdowns,
      useStickyAddToCart,
      allowTwoDayShippingPrimePerk,
      isPreview = false,
      productSizeChanged,
      productAgeGroupChanged,
      productGenderChanged,
      productSingleShoeSideChanged,
      productSizeRangeChanged,
      productSizeUnitChanged,
      validateDimensions,
      styleList,
      hidePerks,
      showSymphonySizeBuyBox,
      removeGenderFilter
    } = this.props;

    const airplaneProps = {
      productAgeGroupChanged,
      productGenderChanged,
      productSingleShoeSideChanged,
      productSizeRangeChanged,
      productSizeUnitChanged,
      validateDimensions
    };

    const { makeColorChooser } = this;
    const { productId, finalSale, styleId } = selectedStyle || {};

    const showProductNotifyMe = showOosNotifyMe && !isGiftCard;

    const sizingChooser = (
      <SizingChooser
        id={id}
        product={product}
        productId={productId}
        productType={productType}
        genders={genders}
        sizing={sizing}
        selectedSizing={selectedSizing}
        dimensionValidation={dimensionValidation}
        sizingPlaceholder={sizingPlaceholder}
        onSizeChange={this.onSizeChange}
        showSizeGender={showSizeGender}
        showSizeChartLink={showSizeChartLink}
        isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
        isSelectSizeTooltipHighlighted={isSelectSizeTooltipHighlighted}
        sizingPredictionId={sizingPredictionId}
        isOnDemandEligible={isOnDemandEligible}
        hasRecommendedSizing={hasRecommendedSizing}
        location={location}
        sizeSymphonyContent={sizeSymphonyContent}
        useDropdowns={useDropdowns}
        productSizeChanged={productSizeChanged}
        styleId={styleId}
        makeProductNotifyMe={makeProductNotifyMe}
        showProductNotifyMe={showProductNotifyMe}
        colorId={selectedStyle.colorId}
        styleList={styleList}
        showSymphonySizeBuyBox={showSymphonySizeBuyBox}
        removeGenderFilter={removeGenderFilter}
        {...airplaneProps}
      />
    );

    return (
      <form method="POST" id="buyBoxForm" action={addToCartAction} onSubmit={onAddToCart} className="flex flex-col gap-y-6">
        {includeColorDropDown && makeColorChooser(showStyleChooserPrefix)}
        {!isGiftCard && sizingChooser}
        {!!addToCartSymphonyContent && (
          <PageContent containerDataId="pdp-buybox" additionalClassName={css.symphonyBuyBoxPageContentCart} slotDetails={addToCartSymphonyContent} />
        )}
        {showAddToCart && (
          <div className={cn(css.atcContainer, { [css.disableClick]: isPreview })}>
            <div className={css.atcWrapper}>
              <AddToCart
                onAddToCart={onAddToCart}
                onOpenProductNotifyMe={onOpenProductNotifyMe}
                productStyle={selectedStyle}
                selectedSizing={selectedSizing}
                sizing={sizing}
                addToCartText={addToCartText}
                lowStockMessage={lowStockMessage}
                useStickyAddToCart={useStickyAddToCart}
                onShowSelectSizeTooltip={onShowSelectSizeTooltip}
                onHideSelectSizeTooltip={onHideSelectSizeTooltip}
                isGiftCard={isGiftCard}
                productDetail={product.detail}
              />
            </div>
          </div>
        )}
        {!hidePerks && allowTwoDayShippingPrimePerk && hydraBelowAddToCartSlot && <BelowAddToCartSlot />}
        {!hidePerks && !finalSale && hideTwoDayShippingPerk && <TwoDayShippingPerk />}
      </form>
    );
  }
}

const connector = connect(null, { stockSelectionCompleted });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default withErrorBoundary('StylePicker', connector(StylePicker));
