import React from 'react';

import useMartyContext from 'hooks/useMartyContext';
import primeLogoSrc from 'images/amazon-prime.svg';

import css from 'styles/components/productdetail/twoDayShippingPerk.scss';

export default function TwoDayShippingPerk() {
  const { testId } = useMartyContext();
  return (
    <div className={css.wrapper} data-test-id={testId('shippingPerkBox')}>
      <div className={css.message}>
        <strong>FREE</strong> upgraded shipping & returns with
      </div>
      <img className={css.image} src={primeLogoSrc} alt="Amazon Prime" />
    </div>
  );
}
