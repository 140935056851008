import { DISPLAY_OUTFIT_COUNT } from 'constants/outfitRecos';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import type { OutfitRecoContent, RecoCardProduct } from 'types/outfitRecos';
import { makeAscii } from 'helpers/index';

export const generateOutfitGroup = (groupId: string, productList: RecoCardProduct[]) => ({
  groupId,
  styleIds: productList.map(product => product.styleId)
});

export const areAllProductsBuyable = (products: RecoCardProduct[], productRelations: { [s: string]: ProductWithRelationsFromCalypso } | undefined) =>
  productRelations && products.every(product => +product.styleId in productRelations);

export const filterValidRecos = (
  outfitRecoContent: OutfitRecoContent[],
  productRelations: { [s: string]: ProductWithRelationsFromCalypso } | undefined
) => {
  const totalValidRecoContent = outfitRecoContent.filter(recoContent => {
    const { productList } = recoContent;
    return areAllProductsBuyable(productList, productRelations);
  });
  return totalValidRecoContent.length >= DISPLAY_OUTFIT_COUNT ? totalValidRecoContent.slice(0, DISPLAY_OUTFIT_COUNT) : undefined;
};

export const generateLabelForProduct = (brandName?: string, productName?: string, color?: string, price?: string, originalPrice?: string) => {
  const encodedBrandName = makeAscii(brandName);
  const encodedProductName = makeAscii(productName);
  let label = '';
  if (encodedBrandName) {
    label = `${encodedBrandName}.`;
  }
  if (encodedProductName) {
    label = `${label} ${encodedProductName}.`;
  }
  if (color) {
    label = `${label} Color ${color}.`;
  }
  if (price) {
    label = `${label} Price ${price}.`;
  }
  if (originalPrice) {
    label = `${label} Original Price ${originalPrice}.`;
  }
  return label;
};
